import React from "react";
import {
    Card,
    Table,   
    Tag
} from "antd";
import utils from "utils";
import NumberFormat from "react-number-format";

const Items = ({ items, currencySymbol }) => {  
     const productTableColumns = [   
    {
      title: "Name",
      dataIndex: "name"  
    },
    {
      title: "Category",
      dataIndex: "category",
      render: (_, record) => <div>{record?.category?.name}</div>,
    },
    {
      title: "Price",
      dataIndex: "price",
      render: (price) => (
        <div>
          <NumberFormat
            displayType={"text"}
            value={price?.toFixed(2)}
            prefix={currencySymbol}
            thousandSeparator={true}
            stringMode
          />
        </div>
      )    
    },
    {
      title: "Price Offer",
      dataIndex: "offer_price",
      render: (price_offer) => (
        <div>
          <NumberFormat
            displayType={"text"}
            value={price_offer?.toFixed(2)}
            prefix={currencySymbol}
            thousandSeparator={true}
            stringMode
          />
        </div>
      )    
    },
    {
      title: "Quantity",
      dataIndex: "quantity",    
    },
    {
      title: "Status",
      dataIndex: "active",
      render: (active) => (
        <Tag
          className="text-capitalize"
          color={active === true ? "cyan" : "red"}
        >
          {active === true ? "Yes" : "No"}
        </Tag>
      )     
    }    
  ];

    return (
        <Card>
            <div className="table-responsive">
                <Table
                    columns={productTableColumns}
                    dataSource={items}
                    rowKey="_id"
                />
            </div>
        </Card>
    )
}

export default Items