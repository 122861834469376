import React, { useContext, useState, useEffect } from 'react'
import { Layout } from 'antd'
import { AUTH_PREFIX_PATH, PUBLIC_PREFIX_PATH } from 'configs/AppConfig'
import { IdentityContext } from 'server/action/identityProvider';
import { useHistory } from "react-router-dom";
import SideBar from './side-bar';
import MenuHeader from './header';
import Contents from './contents';
import IdleTime from './idleTimer';

const BackendLayout = (props) => {
    const history = useHistory();
    const { updateDemoStatus, signOut, suid, token, user, demo, setting, flow } = useContext(IdentityContext);
    const [collapsed, setCollapsed] = useState(true)
    const [logo, setlogo] = useState('center')

    useEffect(() => {
        const script = document.getElementById(process.env.REACT_APP_RECAPTCHAT_KEY);
        if (script) {
            script.remove();
        }
        const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');
        if (recaptchaElems.length) {
            recaptchaElems[0].remove();
        }
    }, [])

    useEffect(() => {
        if (collapsed) {
            setlogo('center')
        } else {
            setlogo('start')
        }
    }, [collapsed])

    const handleSignOut = async () => {
         await signOut()
        history.push(`${PUBLIC_PREFIX_PATH}/`)
    }

    return (
        <>
            <Layout>
                <SideBar logo={logo} setting={setting} collapsed={collapsed} />
                <Layout>
                    <MenuHeader flow={flow} suid={suid} updateDemoStatus={updateDemoStatus} token={token} signOut={handleSignOut} user={user} demo={demo} collapsed={collapsed} setCollapsed={setCollapsed} />
                    <Contents />
                </Layout>
            </Layout >
            <IdleTime signOut={handleSignOut} />
        </>
    )
}

export default BackendLayout;

