export const baseUrl = {
	// HOST: "http://localhost:8000/api"
	HOST:process.env.REACT_APP_HOST
}
// export const IMAGE_BASE_URL = 'http://localhost:8000/images/ 'https://isap-api.herokuapp.com/images/''
export const IMAGE_BASE_URL =process.env.REACT_APP_IMAGE_BASE_URL 

export const AREAS_LIST = [
	{
		name: "Owner",
		value: "10000"
	},
	{
		name: "Customer",
		value: "20000"
	},
	{
		name: "Employee",
		value: "40000"
	},
	{
		name: "Manager",
		value: "30000"
	},
	{
		name: "Driver",
		value: "50000"
	},
]

export const USER_AREA = {
	OWNER: "10000",
	CUSTOMER: "20000",
	MANAGER: "30000",
	EMPLOYEE: "40000",
	DRIVER: "50000"
}

export const ORDER_STATUS = {
	Delivered: "Delivered",
	Pending: "Pending",
	Ready: "Ready",
	Dispatched: "Dispatched"
}

export const SUBSCRIPTION_STATUS = {
	Active: "Active",
	Suspended: "Suspended",
	Cancelled: "Cancelled",
	Pending: "Pending"
}

export const tags = ['Vegetable', 'Frozen Foods', 'Drinks', 'Cooking Oils', 'Fruits', 'Rice', 'Beans', 'Free delivery']
export const sizes = ['Lager', 'Small', 'Medium']
export const colors = ['#000000', '#ffffff', '#006699']
export const currencies = ['$', '£']
export const delivery_distance = ['1', '2' , '3', '5', '10', '15', '20']
export const statuses = ['Delivered', 'Paid', 'Pending', 'Ready', 'Dispatched', 'Unpaid', 'Cancelled']

export const CONFIRM_MESSAGES = {
	Created: "Your changes was saved successfully",
	Updated: "Your changes was saved successfully",
	Deleted: "Delete was successfull",
	PAYMENT_CARD_CREATED: "Payment card created successfully"
}

export const AREA = {
	BACK_END: "10000",
	FRONT_END: "20000"
}

export const USER_ACTION = {
	ADD: "ADD",
	EDIT: "EDIT"
}

export const AUTHENTICATED_TOKEN = 'auth_token'

export const Action_LIST = [
	{
		id:"1",
		title: " ",
		description: `iShopy Buyer is an e-commerce app that allows customers to place orders online. To enable this feature, please click the "Enable" button.`
	}	
]