import React from "react";
import {
  Card,
  Table,
} from "antd";

const ItemsStock = ({ items }) => {
  const productTableColumns = [
    {
      title: "Name",
      dataIndex: "name"
    },
    {
      title: "Quantity",
      dataIndex: "quantity"
    }
  ];

  return (
    <Card>
      <div className="table-responsive">
        <Table
          columns={productTableColumns}
          dataSource={items.sort((a, b) => b.quantity - a.quantity)}
          rowKey="_id"
        />
      </div>
    </Card>
  )
}

export default ItemsStock