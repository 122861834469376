import { customerActionType, commonActionType } from "server/actionType"

const initCustomerState = {
    customers: [],
    customerOrders: [],
    customer_loading: false,
    customer_success: false,  
    customer_account_delete_success: false,
    customer_error: { error: false }
};

const customerReducer = (state = initCustomerState, action) => {

    switch (action.type) {

        case customerActionType.FETCH_CUSTOMERS:
            return {
                ...state,
                customers: action.payload,
                customer_loading: false
            }

        case customerActionType.CUSTOMER_ACCOUNT_DELETE:
             console.log(action.payload)
            return {
                ...state,             
                customer_loading: false,
                customer_account_delete_success : true
            }

        case customerActionType.FETCH_ORDERS:

            return {
                ...state,
                customerOrders: action.payload,
                customer_loading: false
            }

        case customerActionType.RESET:

            return {
                ...state,
                customer_loading: false,
                customer_success: false,
                customer_error: { error: false }
            }

        case customerActionType.ERROR:
            return {
                ...state,
                customer_success: false,
                customer_loading: false,
                customer_error: action.payload
            }

        case commonActionType.LOADING:
            return {
                ...state,
                customer_loading: true
            };

        default:
            return state;
    }

}

export default customerReducer