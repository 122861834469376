export const userActionType = {
    CREATE: "CREATE_USER",
    EDIT: "EDIT_USER",
    DELETE: "DELETE_USER",
    FETCH: "FETCH_USER",
    ERROR: "ERROR_USER",
    FETCH_SINGLE: "FETCH_SINGLE_USER",
    FILTER: "FILTER_USER",  
    RESET: "RESET_USER",   
}

export const indentityActionType = {
    FORGET: "FORGET",
    SIGN_OUT: "SIGNOUT",
    SIGN_IN: "SIGNIN",
    SIGN_UP: "SIGNUP",
    AUTO_SIGN_IN: "AUTO_SIGN_IN",   
    RESET: "RESET_INDENTITY",
    DEMO_STATUS: "DEMO_STATUS",
    PASSWORD_RESET: "PASSWORD_RESET",
    UPDATE_SHOW_ONBOARDING_HELP: "UPDATE_SHOW_ONBOARDING_HELP",
    CONTACT_US: "CONTACT_US",
    FORGET_PASSWORD_ERROR: "FORGET_PASSWORD_ERROR",
    SIGN_IN_PASSWORD_ERROR: "SIGN_IN_PASSWORD_ERROR",
    PASSWORD_RESET_ERROR: "PASSWORD_RESET_ERROR",
    ERROR: "ERROR_IDENTITY",
}

export const commonActionType = {
    LOADING: "LOADING",
    LOADING_CATALOGUE : "LOADING_CATALOGUE",
    subscription_upgrade_loading : "subscription_upgrade_loading",    
    ERROR: "ERROR",
    FORGET_PASSWORD_LOADING: "FORGET_PASSWORD_LOADING",
    RESET_PASSWORD_LOADING: "RESET_PASSWORD_LOADING",
    USER_LOADING: "USER_LOADING"
}

export const categoryActionType = {
    CREATE: "CREATE_CATEGORY",
    EDIT: "EDIT_CATEGORY",
    DELETE: "DELETE_CATEGORY",
    FETCH: "FETCH_CATEGORY", 
    FETCH_SINGLE: "FETCH_SINGLE_CATEGORY",
    OFF_EDIT: "OFF_EDIT_CATEGORY",
    RESET: "RESET_CATEGORY",
    ERROR: "ERROR_CATEGORY",
}

export const productActionType = {
    CREATE: "CREATE_PRODUCT",
    EDIT: "EDIT_PRODUCT",
    DELETE: "DELETE_PRODUCT",
    FETCH: "FETCH_PRODUCT",
    ERROR: "ERROR_PRODUCT",
    FETCH_SINGLE: "FETCH_SINGLE_PRODUCT",
    DUPLICATE: "DUPLICATE_PRODUCT",
    OFF_EDIT: "OFF_EDIT_PRODUCT",
    RESET_SUCCESS: "RESET_SUCCESS",
    RESET_ERROR: "RESET_ERROR_PRODUCT",
    RESET: "RESET_PRODUCT",
    CREATE_ITEM_STOCK: "CREATE_ITEM_STOCK",
    REMOVE_STOCK: "REMOVE_STOCK"  
}

export const productStockActionType = {
    STOCK_RESET: "STOCK_RESET",
    CREATE_ITEM_STOCK: "CREATE_ITEM_STOCK",
    REMOVE_STOCK: "REMOVE_STOCK",
    LOAD_STOCK: "LOAD_STOCK",
    ERROR: "ERROR_STOCK",
}

export const settingsActionType = {
    CREATE: "CREATE_SETTINGS",
    EDIT: "EDIT_SETTINGS",
    DELETE: "DELETE_SETTINGS",
    FETCH_ONE: "FETCH_ONE_SETTINGS",
    ERROR: "ERROR_SETTINGS",
    FETCH_SINGLE: "FETCH_SINGLE_SETTINGS",
    OFF_EDIT: "OFF_EDIT_SETTINGS",
    RESET: "RESET_SETTINGS",
    UPDATE_PRICE: "UPDATE_PRICE_SETTINGS",
    CREATE_DELIVERY_SLOTS: "CREATE_DELIVERY_SLOTS",
    UPDATE_DELIVERY_SLOTS: "UPDATE_DELIVERY_SLOTS",
    REMOVE_DELIVERY_SLOTS: "REMOVE_DELIVERY_SLOTS",
    CREATE_DELIVERY_PAYMENT_PROVIDER: "CREATE_DELIVERY_PAYMENT_PROVIDER",
    UPDATE_DELIVERY_PAYMENT_PROVIDER: "UPDATE_DELIVERY_PAYMENT_PROVIDER",
    REMOVE_DELIVERY_PAYMENT_PROVIDER: "REMOVE_DELIVERY_PAYMENT_PROVIDER",
    DEMO: "DEMO",
    DEMO_SETTING: "DEMO_SETTING",
    POPULATE_CATALOGUE: "POPULATE_CATALOGUE",
    VERIFY_ONLINE: "VERIFY_ONLINE",
    ENABLE_ONLINE_SHOPPING: "ENABLE_ONLINE_SHOPPING",
    VERIFY_ADDRESS: "VERIFY_ADDRESS",
    CREATE_SHIPPING_METHOD: "CREATE_SHIPPING_METHOD",
    UPDATE_SHIPPING_METHOD: "UPDATE_SHIPPING_METHOD",
    REMOVE_SHIPPING_METHOD: "REMOVE_SHIPPING_METHOD",   
    CHANGE_ONLINE_SHOPPING: "CHANGE_ONLINE_SHOPPING",
    ELIGIBLE_FOR_ENABLING: "ELIGIBLE_FOR_ENABLING",
 
}

export const orderActionType = {
    DELETE: "DELETE_ORDER",
    FETCH_ONE: "FETCH_ONE_ORDER",
    FETCH: "FETCH_ORDER",
    FILTER_ORDER_BY_DATES: "FILTER_ORDER_BY_DATES",
    FETCH_TOP_SALES_ITEMS: "FETCH_TOP_SALES_ITEMS",
    CREATE_ORDER: "CREATE_ORDER",
    CREATE_WORKFLOW: "CREATE_WORKFLOW",
    ERROR: "ERROR_ORDER",
    RESET: "RESET_ORDER",
    SWITCH_OFF_NOTIFICATION: "SWITCH_OFF_NOTIFICATION",
    UPDATE_STATUS: "UPDATE_STATUS",   
    UPDATE_PAYMENT_STATUS : "UPDATE_PAYMENT_STATUS"
}

export const dashboardActionType = {
    FETCH_ORDER_STATUS: "FETCH_ORDER_STATUS",
    FETCH_WEEKLY_SALES: "FETCH_WEEKLY_SALES",
    FETCH_PAYMENT_STATUS: "FETCH_PAYMENT_STATUS",
    FETCH_TOP_ORDERS: "FETCH_TOP_ORDERS",
    FETCH_DASHBOARD_AGGREGATES: "FETCH_DASHBOARD_AGGREGATES",
    ERROR: "ERROR_DASHBOARD",
}

export const appConfigActionType = {
    TOGGLE_SIDE_BAR: "TOGGLE_SIDE_BAR"
}

export const customerActionType = {
    FETCH_ORDERS: "FETCH_CUSTOMER_ORDERS",
    FETCH_CUSTOMERS: "FETCH_CUSTOMERS",
    RESET: "RESET_CUSTOMER",
    ERROR: "ERROR_CUSTOMER",
    CUSTOMER_ACCOUNT_DELETE: "CUSTOMER_ACCOUNT_DELETE",
}

export const subscriptionActionType = {
    UPDATE: "UPDATE_SUBSCRIPTION",
    CANCEL: "CANCEL_SUBSCRIPTION",
    CREATE: "CREATE_SUBSCRIPTION",
    FETCH: "FETCH_SUBSCRIPTION",
    CUSTOMER_INVOICES: "CUSTOMER_INVOICES",
    FETCH_UP_COMING_INVOICE: "FETCH_UP_COMING_INVOICE",
    CREATE_PAYMENT_METHOD: "CREATE_PAYMENT_METHOD",
    RESET_SUBSCRIPTION: "RESET_SUBSCRIPTION",
    ERROR: "ERROR_SUBSCRIPTION",
}

export const deliverySlotsActionType = {
    CREATE: "CREATE_DELIVERY",
    UPDATE: "UPDATE_DELIVERY",
    REMOVE: "REMOVE_DELIVERY",
    RESET: "RESET_DELIVERY",
    ERROR: "ERROR_DELIVERY",
}

export const shippingMethodActionType = {   
    RESET: "RESET_SHIPPING_METHOD",
    ERROR: "ERROR_SHIPPING_METHOD",
}

export const notificationsActionType = {
    CLEAR_NOTIFICATION: "CLEAR_NOTIFICATION",
    FETCH_NOTIFICATIONS: "FETCH_NOTIFICATIONS",
    UPDATE_NOTIFICATION: "UPDATE_NOTIFICATION",
    ERROR: "ERROR_NOTIFICATION",
} 

export const onBoardingTodoActionType = {  
    UPDATE_ONBOARDING_TODO: "UPDATE_ONBOARDING_TODO",
    FETCH_ONBOARDING_TODO: "FETCH_ONBOARDING_TODO",
    ERROR: "ERROR_BOARDING"
}
