import React, { useState, useEffect } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { Modal } from 'antd';


const IdleTime = (props) => {
    const readTimeOut = 1000 * 60 * 15
    const [idle, setIdle] = useState(false)
    const [timeOut, setTimeOut] = useState(false)
    const [showModal, setShowModal] = useState(false)
    let timer = -1
    
    const onAction = (e) => {

    }

    const onActive = (e) => {
        clearTimeout(timer);       
        setIdle(false)      
    }

    const onIdle = (e) => {
        setShowModal(true)
        setIdle(true)
        setTimeOut(false)

        timer = setTimeout(() => {
            setTimeOut(true)
        },  readTimeOut);
    }

    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: readTimeOut,
        onIdle: onIdle,
        onActive: onActive,
        onAction: onAction,
        debounce: 500
    })

    useEffect(() => {      

        if (getRemainingTime() === 0 && timeOut) {
            clearTimeout(timer);
            props.signOut()
        }

    }, [getRemainingTime(), timeOut])

    const handleOk = () => {
        setIdle(false)
        setShowModal(false)
    }

    const handleCancel = () => {
        props.signOut()
    }

    return (
        <>
            <Modal title="Session Timeout" okText="Continue"
                cancelText="No, Logout" visible={showModal} onOk={handleOk} onCancel={handleCancel}>
                <p>Please be aware that your session is about to expire. If you don't click Continue to stay in within 2 minues, you will automatically be logged out. </p>
            </Modal>
        </>
    )

}

export default IdleTime