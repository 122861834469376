import axios from "axios";

import { settingsActionType } from "server/actionType";
import { commonActionType } from "server/actionType";
import handleError from "utils/error";

const NOMINATIM_BASE_URL = "https://nominatim.openstreetmap.org/search?";

export const createSetting = (params, token) => async (dispatch) => {
  dispatch({ type: commonActionType.LOADING });

  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data"
    },
    withCredentials: true
  };

  await axios
    .post(`${process.env.REACT_APP_HOST}/settings/create`, params, config)
    .then((response) => {
      dispatch({
        type: settingsActionType.CREATE,
        payload: response.data,
      });
    })
    .catch((error) => {
      handleError(settingsActionType.ERROR, error, dispatch);
    });
};

export const updateOneDemo = (id, params, token) => async (dispatch) => {
  try {
    dispatch({ type: commonActionType.LOADING });

    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      withCredentials: true
    };

    const response = await axios.put(
      `${process.env.REACT_APP_HOST}/settings/demo/${id}`,
      params,
      config
    );
    dispatch({ type: settingsActionType.DEMO, payload: response.data });
  } catch (error) {
    handleError(settingsActionType.ERROR, error, dispatch);
  }
};

export const updateOneSetting = (id, body, token) => async (dispatch) => {
  try {
    dispatch({ type: commonActionType.LOADING });

    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
      },
      withCredentials: true
    };
    const response = await axios.put(
      `${process.env.REACT_APP_HOST}/settings/${id}`,
      body,
      config
    );
    dispatch({ type: settingsActionType.EDIT, payload: body });
  } catch (error) {
    handleError(settingsActionType.ERROR, error, dispatch);
  }
};

export const deleteOneSetting = (id, public_id, token) => async (dispatch) => {
  try {
    dispatch({ type: commonActionType.LOADING });

    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      withCredentials: true
    };

    const response = await axios.delete(
      `${process.env.REACT_APP_HOST}/settings/${id}/${public_id}`,
      config
    );
    dispatch({ type: settingsActionType.DELETE, payload: id });
  } catch (error) {
    handleError(settingsActionType.ERROR, error, dispatch);
  }
};

export const resetSetting = () => (dispatch) => {
  dispatch({ type: settingsActionType.RESET });
};

export const updateSettingPriceId = (priceId) => (dispatch) => {
  dispatch({ type: settingsActionType.UPDATE_PRICE, payload: priceId });
};

export const demoSetting = (setting) => (dispatch) => {
  dispatch({ type: settingsActionType.FETCH_ONE, payload: setting });
};

export const fetchOneSetting = (token) => async (dispatch) => {
  try {
    dispatch({ type: commonActionType.LOADING });

    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      withCredentials: true
    };

    const response = await axios.get(
      `${process.env.REACT_APP_HOST}/settings`,
      config
    );
   
    dispatch({ type: settingsActionType.FETCH_ONE, payload: response.data });
  } catch (error) {
    handleError(settingsActionType.ERROR, error, dispatch);
  }
};

export const verifyOnline = (token) => async (dispatch) => {
  try {
    dispatch({ type: commonActionType.LOADING });

    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      withCredentials: true
    };

    const response = await axios.get(
      `${process.env.REACT_APP_HOST}/settings/verify-online`,
      config
    );
    dispatch({
      type: settingsActionType.VERIFY_ONLINE,
      payload: response.data,
    });
  } catch (error) {
    handleError(settingsActionType.ERROR, error, dispatch);
  }
};

export const isEligibleForEnabling = (enable_shopping) => async (dispatch) => {
  try {
    dispatch({ type: commonActionType.LOADING });

    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      withCredentials: true
    };

    const response = await axios.post(
      `${process.env.REACT_APP_HOST}/settings/enable-online-shopping`, { enable_shopping },
      config
    );

    const { isEligible = false, checklist = [] } = response.data  
    dispatch({
      type: settingsActionType.ELIGIBLE_FOR_ENABLING,
      payload: { isEligible, checklist },
    });
  } catch (error) {
    handleError(settingsActionType.ERROR, error, dispatch);
  }
};

export const changeOnlineShopping = (enable_shopping) => async (dispatch) => {
  try {
    dispatch({ type: commonActionType.LOADING });

    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      withCredentials: true
    };

    await axios.post(
      `${process.env.REACT_APP_HOST}/settings/change-online-shopping`, { enable_shopping },
      config
    );
    dispatch({
      type: settingsActionType.CHANGE_ONLINE_SHOPPING,
      payload: enable_shopping,
    });
  } catch (error) {
    handleError(settingsActionType.ERROR, error, dispatch);
  }
};

export const verifyAddress = (query) => async (dispatch) => {
  dispatch({ type: commonActionType.LOADING });
  const params = {
    q: query,
    format: "json",
    addressdetails: 1,
    polygon_geojson: 0,
  };

  const queryString = new URLSearchParams(params).toString();
  const requestOptions = {
    method: "GET",
    redirect: "follow",
  };
  fetch(`${NOMINATIM_BASE_URL}${queryString}`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      dispatch({
        type: settingsActionType.VERIFY_ADDRESS,
        payload: result,
      });
    })
    .catch((err) => {
      handleError(settingsActionType.ERROR, err, dispatch);
    });
};
