import React, { useState, useEffect } from 'react';
import { Menu, Dropdown, Badge, Avatar, List, Button, Modal, Tooltip } from 'antd';
import {
  MailOutlined,
  BellOutlined,
  WarningOutlined,
  CheckCircleOutlined
} from '@ant-design/icons';
import { fetch, updateOne } from 'server/action/notificationAction';
import demoNotices from '../../configs/notification.json'
import { connect } from "react-redux";
import NotificationView from './notification-side-view'
import { DATE_FORMAT_DD_MM_YYYY } from 'configs/DateConstant';
import moment from 'moment';
import { Markup } from 'interweave';

export const Notification = (props) => {
  const [visible, setVisible] = useState(false);
  const { fetch, token, suid, updateOne, notifications, demo } = props
  const [notices, setNotices] = useState([])
  const [viewNotices, setViewNotices] = useState(false)
  const [showNotification, setShowNotification] = useState({
    title: "",
    description: "",
    visible: false
  })

  useEffect(() => {
    if (!demo) {
      fetch(token)
    }

  }, [demo])

  useEffect(() => {
    if (demo) {
      setNotices(demoNotices)
    } else {

      if (notifications) {
        const test = notifications.sort(function (a, b) {
          return a._id - b._id;
        });
        setNotices(test)
      }
    }
  }, [notifications, demo])


  const getIcon = icon => {
    switch (icon) {
      case 'mail':
        return <MailOutlined />;
      case 'alert':
        return <WarningOutlined />;
      case 'check':
        return <CheckCircleOutlined />
      default:
        return <MailOutlined />;
    }
  }

  const showNotices = () => {
    setViewNotices(true);
    setVisible(false)
  };

  const closeNotice = () => {
    setViewNotices(false);
  };

  const onShowNotificationDetails = (notification) => {
    setVisible(false)
    setViewNotices(false);
    setShowNotification({ title: notification.title, description: notification.description, visible: true })

    notification.status = true
    updateOne(notification, token, suid)
  }

  const getNotificationBody = list => {
    return list?.length > 0 ?
      <List
        size="small"
        itemLayout="horizontal"
        dataSource={list}
        renderItem={item => (
          <List.Item className="list-clickable" onClick={() => onShowNotificationDetails(item)}>
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              <div className="pr-3">
                {<Avatar className={`ant-avatar-${item?.status === true ? "success" : item.type}`} style={{ marginRight: 4 }} icon={getIcon(item.icon)} />}
              </div>
              <div style={{ width: '100%' }}>
                <span className="font-weight text-dark">{item.title} </span><br />
                <small style={{ marginRight: 5 }}>{moment(item.createdAt).format(DATE_FORMAT_DD_MM_YYYY)}</small>
                <small>{item.time}</small>
              </div>

            </div>
          </List.Item>
        )}
      />
      :
      <div className="empty-notification">
        <img src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg" alt="empty" />
        <p className="mt-3">You have viewed all notifications</p>
      </div>;
  }

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  }

  const notificationList = (
    <div className="nav-dropdown nav-notification">
      <div className="nav-notification-header d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Notifications</h4>
      </div>
      <div className="nav-notification-body">
        {
          getNotificationBody(notices)
        }
      </div>
      {
        notices?.length > 0 ?
          <div className="nav-notification-footer d-flex justify-content-center align-items-center">
            <Button className="d-block" type="link" onClick={() => showNotices()} size="small">View all </Button>
          </div>
          :
          null
      }
    </div>
  );

  return (
    <Tooltip title="Notifications">
      <Dropdown
        placement="bottomRight"
        overlay={notificationList}
        onVisibleChange={handleVisibleChange}
        visible={visible}
        trigger={['click']}
      >
        <Menu mode="horizontal">
          <Menu.Item>
            <Badge count={(notices)?.filter(x => x.status === false).length}>
              <BellOutlined className="nav-icon mx-auto" type="bell" />
            </Badge>
          </Menu.Item>
        </Menu>
      </Dropdown>
      <NotificationView notices={notices} visible={viewNotices} onShowNotificationDetails={onShowNotificationDetails} close={() => { closeNotice() }} />
      < Modal
        title={null}
        visible={showNotification?.description?.length && (showNotification.visible)}
        onOk={() => setShowNotification({ visible: false, title: "", description: "" })}
        onCancel={() => setShowNotification({ visible: false })}
      >
        {<Markup content={showNotification?.description} />}
      </Modal >
    </Tooltip>
  )
}

const mapStateToProps = state => {
  const { notifications } = state.notification;
  return { notifications }
}

const mapDispatchToProps = {
  fetch,
  updateOne
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(Notification));
