import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { PUBLIC_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const PublicUi = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route path={`${PUBLIC_PREFIX_PATH}/v2`} component={lazy(() => import(`./v2`))} />   
        <Route path={`${PUBLIC_PREFIX_PATH}/stripe-payout-error`} component={lazy(() => import(`./stripe-payout-error`))} />     
        <Route path={`${PUBLIC_PREFIX_PATH}/stripe-payout-success`} component={lazy(() => import(`./stripe-payout-success`))} />   
        <Route path={`${PUBLIC_PREFIX_PATH}/customer-account-delete-request/:id`} component={lazy(() => import(`./customer-account-delete-request`))} />   
        <Route path={`${PUBLIC_PREFIX_PATH}/customer-account-delete-success`} component={lazy(() => import(`./customer-account-delete-success`))} />   
        <Route path={`${PUBLIC_PREFIX_PATH}/privacy-policy`} component={lazy(() => import(`./privacy-policy`))} />    
        <Route path={`${PUBLIC_PREFIX_PATH}/delivery-privacy-policy`} component={lazy(() => import(`./delivery-privacy-policy`))} />   
        <Route path={`${PUBLIC_PREFIX_PATH}/sign-up`} component={lazy(() => import(`./sign-up`))} />  
        <Route path={`${PUBLIC_PREFIX_PATH}/success`} component={lazy(() => import(`./success`))} />     
        <Redirect from={`${PUBLIC_PREFIX_PATH}`} to={`${PUBLIC_PREFIX_PATH}/v2`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(PublicUi);
