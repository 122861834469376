
import React, { useEffect } from 'react'
import { Switch, Route, } from "react-router-dom";
import { PublicUi } from 'ui/public';

export const PublicLayout = () => {

	useEffect(() => {
		const script = document.getElementById(process.env.REACT_APP_RECAPTCHAT_KEY);
		if (script) {
			script.remove();
		}
		const recaptchaElems = document.getElementsByClassName('grecaptcha-badge');	
		if (recaptchaElems.length) {
			recaptchaElems[0].remove();		
		}
	},[])

	return (
		<>
			<Switch>
				<Route path="" component={PublicUi} />
			</Switch>
		</>
	)
}

export default PublicLayout
