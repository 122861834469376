import axios from 'axios';
import { notificationsActionType } from "server/actionType";
import { commonActionType } from "server/actionType";
import handleError from 'utils/error';

export const clear = (token) => async (dispatch) => {

  dispatch({ type: commonActionType.LOADING });

  const config = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'     
    },
    withCredentials: true
  };

  await axios
    .delete(`${process.env.REACT_APP_HOST}/notification/clear`, config)
    .then((response) => {
      dispatch({
        type: notificationsActionType.CLEAR_NOTIFICATION,
        payload:response?.data
      });
    })
    .catch((error) => {
      handleError(notificationsActionType.ERROR, error, dispatch)
    });
};
export const fetch = (token) => async (dispatch) => {

  dispatch({ type: commonActionType.LOADING });

  const config = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'    
    },
    withCredentials: true
  };

  await axios
    .get(`${process.env.REACT_APP_HOST}/notification/fetch`, config)
    .then((response) => {
      dispatch({
        type: notificationsActionType.FETCH_NOTIFICATIONS,
        payload:response?.data
      });      
    })
    .catch((error) => {
      handleError(notificationsActionType.ERROR, error, dispatch)
    });
};

export const updateOne = (params, token, suid) => async (dispatch) => {

  dispatch({ type: commonActionType.LOADING });
  
  const config = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'  
    },
    withCredentials: true
  };

  const body =  { notification_id: params._id }
  await axios
    .put(`${process.env.REACT_APP_HOST}/notification/${suid}`, body, config)
    .then((response) => {
      dispatch({
        type: notificationsActionType.UPDATE_NOTIFICATION,
        payload: params
      });
    })
    .catch((error) => {
      handleError(notificationsActionType.ERROR, error, dispatch)
    });
};




