import { productStockActionType, commonActionType } from "server/actionType"

const initState = {
    stock: {},
    stocks: [],
    product_stock_error: { error : false},
    product_stock_loading: false,
    product_stock_success: false
};

const productStockReducer = (state = initState, action) => {

    switch (action.type) {

        case productStockActionType.CREATE_ITEM_STOCK:
            return {
                ...state,
                stocks: [...state.stocks, action.payload],
                product_stock_loading: false,
                product_stock_success: true
            }

        case productStockActionType.REMOVE_STOCK:
            return {
                ...state,
                stocks: state.stocks.filter(x => x._id !== action.payload),
                product_stock_loading: false,
                product_stock_success: true
            }

        case productStockActionType.LOAD_STOCK:
            return {
                ...state,
                stocks: action.payload
            }

        case productStockActionType.STOCK_RESET:
            return {
                ...state,
                product_stock_error: { error : false},
                product_stock_loading: false,
                product_stock_success: false
            }

        case productStockActionType.ERROR:
            return {
                ...state,
                product_stock_error: action.payload,
                product_stock_loading: false,
                product_stock_success: false
            }

        case commonActionType.LOADING:
            return {
                ...state,
                product_loading: true
            };

        default:
            return state;
    }

}

export default productStockReducer