import { notificationsActionType, commonActionType } from "server/actionType"

const initNotificationState = {
    notifications: [],
    notification_loading: false,
    notification_success: false,
    notification_error: { error: false }
};

const notificationReducer = (state = initNotificationState, action) => {

    switch (action.type) {

        case notificationsActionType.FETCH_NOTIFICATIONS:
            return {
                ...state,
                notifications: action.payload,
                notification_success: false,
                notification_loading: false,
            }

        case notificationsActionType.UPDATE_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.map((notification) => { return notification._id === action.payload._id ? action.payload : notification }),
                notification_success: false,
                notification_loading: false,
            }


        case notificationsActionType.CLEAR_NOTIFICATION:

            return {
                ...state,
                notifications: [],
                notification_success: false,
                notification_loading: false
            }


        case notificationsActionType.ERROR:
            return {
                ...state,
                notification_success: false,
                notification_loading: false,
                notification_error: action.payload
            }

        case commonActionType.LOADING:
            return {
                ...state,
                notification_loading: true
            };

        default:
            return state;
    }

}

export default notificationReducer