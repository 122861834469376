import React, { useContext } from "react";
import {
    Card,
    Table,   
    Badge,    
} from "antd";
import NumberFormat from "react-number-format";
import moment from "moment";
import { IdentityContext } from "server/action/identityProvider";

const Sales = ({ orders }) => {
    const { currencySymbol = "£" } = useContext(IdentityContext);
    const getPaymentStatus = (status) => {
        if (status === "Paid") {
            return "success";
        }
        if (status === "UnPaid") {
            return "warning";
        }

        if (status === "Refunded") {
            return "error";
        }
        return "";
    };

    const orderTableColumns = [
        {
            title: "CreatedAt",
            dataIndex: "createdAt",
            render: (date) => <span>{moment(date).format("DD/MM/YYYY")} </span>           
        },
        {
            title: "Sales Channel",
            dataIndex: "order_channel"          
        },
        {
            title: "Payment Status",
            dataIndex: "payment_status",
            render: (_, record) => (
                <>
                    <Badge status={getPaymentStatus(record.payment_status)} />
                    <span>{record.payment_status}</span>
                </>
            )            
        },
        {
            title: "Subtotal",
            dataIndex: "total",
            render: (price) => (
                <div>
                    <NumberFormat
                        displayType={"text"}
                        value={Math.round(price).toFixed(2)}
                        prefix={currencySymbol}
                        thousandSeparator={true}
                    />
                </div>
            )       
        },
        {
            title: "Tax",
            dataIndex: "total_tax",
            render: (tax) => (
                <div>
                    <NumberFormat
                        displayType={"text"}
                        value={Math.round(tax).toFixed(2)}
                        prefix={currencySymbol}
                        thousandSeparator={true}
                    />
                </div>
            )     

        },
        {
            title: "Delivery Charge",
            dataIndex: "deliveryCharges",
            className: "text-center ",
            render: (deliveryCharges) => (
                <div>
                    <NumberFormat
                        displayType={"text"}
                        value={Math.round(deliveryCharges).toFixed(2)}
                        prefix={currencySymbol}
                        thousandSeparator={true}
                    />
                </div>
            )
        },
        {
            title: "Total",
            dataIndex: "grand_total",
            className: "text-center ",
            render: (grand_total) => (
                <div>
                    <NumberFormat
                        displayType={"text"}
                        value={Math.round(grand_total).toFixed(2)}
                        prefix={currencySymbol}
                        thousandSeparator={true}
                    />
                </div>
            )        
        }
    ];

    return (
        <Card>
            <div className="table-responsive">
                <Table
                    columns={orderTableColumns}
                    dataSource={orders}
                    rowKey="_id"
                />
            </div>
        </Card>
    )
}


export default Sales