
import { appConfigActionType } from "server/actionType"

const initAppConfigState = {
    collapsed: true
};

const appConfigReducer = (state = initAppConfigState, action) => {
    switch (action.type) {
        case appConfigActionType.TOGGLE_SIDE_BAR:
            return {
                ...state,
                collapsed: action.collapsed
            };
        default:
            return state;
    }
};


export default appConfigReducer