
const handleError = (actionType, error, dispatch) => {

    console.log("++++++++++++", error?.response)
        
    if (error.response) {
        if (error.response.status === 400) {
            dispatch({
                type: actionType,
                payload: { error : true, message: error?.response?.data?.error, status: 400, showInModalDialog: false }
            });
        } else if (error.response.status === 404) {
            dispatch({
                type: actionType,
                payload: { error : true, message: "Your request could not be processed. Please try again.", status: 404, showInModalDialog: false }
            });
        } else if (error.response.status === 401) {
            dispatch({
                type: actionType,
                payload: { error : true, message: error?.response?.data?.error, status: 401, showInModalDialog: false }
            });
        } else if (error.response.status === 403) {
            dispatch({
                type: actionType,
                payload: { error : true, message: "Forbidden! You are not allowed to access this resource.", status: 403, showInModalDialog: false }
            });
        }
        else if (error.response.status === 406) {                           
            dispatch({
                type: actionType,
                payload: { error : true, message: error?.response?.data?.error, status: 406, showInModalDialog: true }
            });
        }
        else if (error.response.status === 500) {
            dispatch({
                type: actionType,
                payload: { error : true, message: error?.response?.data?.error, status: 500, showInModalDialog: false }
            });
        } else {
            dispatch({
                type: actionType,
                payload: { error : true, message: error?.response?.data?.error, status: error.response.status, showInModalDialog: false }
            });
        }
    } else if (error.message) {
        dispatch({
            type: actionType,
            payload: { error : true, message: error.message, status: "", showInModalDialog: false }
        });
    }
};

export default handleError;
