
export const REPORT_RULES = {
  report: {
    startDateStr: [
      {
        pattern: /^.+$/,
        message: 'start date is required'
      }
    ],
    endDateStr: [
      {
        pattern: /^.+$/,
        message: 'end date is required'
      }
    ],

  },

  fields: {
    startDateStr: '',
    endDateStr: '',
    payment_status: '',
    order_channel: ''
  },
};


