import React from "react";
import {
    Card,
    Table,   
} from "antd";

const TopItemSales = ({ topItems }) => {  
    const orderTableColumns = [        
        {
            title: "Item Name",
            dataIndex: "itemName"          
        },
        {
            title: "Total Sales",
            dataIndex: "count",
             className: "text-center",                 
        },     
    ];

    return (
        <Card>
            <div className="table-responsive">
                <Table
                    columns={orderTableColumns}
                    dataSource={topItems}
                    rowKey="_id"
                />
            </div>
        </Card>
    )
}

export default TopItemSales