import React, { useState, useContext } from 'react';
import {
  Button,
  Row,
  Col,
  Select,
  Divider,
  Space,
  Radio,
  DatePicker,
  Drawer,
} from "antd";
import {
  DownloadOutlined
} from "@ant-design/icons";
import Scrollbars from "react-custom-scrollbars";
import NumberFormat from "react-number-format";
import { connect } from "react-redux";
import { Download } from '../../../utils/helper';
import Sales from '../reports/sales';
import { filterOrderByDates, getTopItemsBySales } from "server/action/orderAction";
import {
  fetchProducts,
} from "server/action/productAction";
import TopItemSales from '../reports/top-items';
import { IdentityContext } from 'server/action/identityProvider';
import { validate } from 'utils/validator';
import { REPORT_RULES } from './rules';
import Items from '../reports/items';
import ItemsStock from '../reports/itemsStock';

const OrderReportPanel = (props) => {
  const { currencySymbol } = useContext(IdentityContext);
  const { filterOrderByDates, filteredOrder =[], getTopItemsBySales, topSalesItems =[], products = [], fetchProducts, product_error, order_error, resetOrder, isPanelOpen, handlePanelToggle } = props;
  const [fields, setFields] = useState(REPORT_RULES.fields);
  const [errorMessages, setErrorMessages] = useState({});
  const [selected, setSelected] = useState("Sales");

  const handleSearch = async () => {
    try {

      if (selected === "Sales" || selected === "TopItems") {
        setErrorMessages({});
        const { hasError, errors } = validate(fields, REPORT_RULES.report);
        if (hasError) {
          setErrorMessages(errors);
          return false;
        }
      }

      switch (selected) {
        case "Sales":
          await filterOrderByDates(fields)
          break;
        case "TopItems":
          await getTopItemsBySales(fields)
          break;
        case "Items":
        case "ItemsStock":
          fetchProducts({})
          break;
        default:
          break;
      }

    } catch (error) {
      console.log(error)
    }
  };

  const handleDownload = () => {
    try {

      switch (selected) {
        case "Sales":
          Download(filteredOrder.orders)
          break;
        case "TopItems":
          Download(topSalesItems.topItems.map((item) => { return { name: item.itemName, count: item.count } }).sort((a, b)=> a.itemName - b.itemName ))
          break;
        case "Items":
          Download(products.map((item) => { return { name: item.name, description: item.description, price: item.price, offer_price: item.offer_price, cost: item.cost, unit: item.unit, taxable: item.taxable, category: item.category.name, quantity: item.quantity, active: item.active } }))
          break;
        case "ItemsStock":
          Download(products.map((item) => { return { name: item.name, quantity: item.quantity } }))
          break;
        default:
          break;
      }

    } catch (error) {
      console.log(error)
    }
  }

  const handleDateChange = (name, dateString) => {
    setFields((pre) => {
      return {
        ...pre,
        [name]: dateString,
      }
    })
  };

  const onSelected = (e) => {
    setSelected(e.target.value)
  };

  const { endDateStr, startDateStr } = errorMessages || {};

  const getStartDateBorderColor = () => {
    if (startDateStr && startDateStr.message) {
      return { borderColor: '#ff1a8c' };
    }
    return null;
  };

  const getEndDateBorderColor = () => {
    if (endDateStr && endDateStr.message) {
      return { borderColor: '#ff1a8c' };
    }
    return null;
  };

  return (
    <Drawer
      width={1024}
      title="Reports"
      onClose={handlePanelToggle}
      closable={true}
      visible={isPanelOpen}
      styles={{
        body: {
          paddingBottom: 80,
        },
      }}
    >
      <div className="w-100">
        <Scrollbars
          style={{ height: '100vh', zIndex: 9999 }}
          createContext={true}
          noScrollX={true}
        >
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Radio.Group onChange={onSelected} value={selected}>
                <Radio.Button value="Sales">Sales</Radio.Button>
                <Radio.Button value="TopItems">Top Items</Radio.Button>
                <Radio.Button value="Items">Items</Radio.Button>
                <Radio.Button value="ItemsStock">Items Stock</Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
          <Divider dashed />
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={24} className='mb-2'>
              <div className="mb-1 d-md-flex flex-row justify-content-between align-items-center">
                <Space>
                  {
                    (selected === "Sales" || selected === "TopItems") &&
                    (
                      <>
                        <DatePicker style={getStartDateBorderColor()} onChange={(date, dateString) => handleDateChange("startDateStr", dateString)} name='startDateStr' />
                        <DatePicker style={getEndDateBorderColor()} onChange={(date, dateString) => handleDateChange("endDateStr", dateString)} name='endDateStr' />
                      </>
                    )
                  }
                  {
                    selected === "Sales" && (
                      <>
                        <Select
                          placeholder="Select Payment Status"
                          style={{ width: 200 }}
                          options={[
                            { value: '', label: 'Select Payment Status' },
                            { value: 'Paid', label: 'Paid' },
                            { value: 'UnPaid', label: 'UnPaid' },
                          ]}
                          onChange={(value) => handleDateChange("payment_status", value)}
                        />
                        <Select
                          placeholder="Select Sales Channel"
                          style={{ width: 200 }}
                          options={[
                            { value: '', label: 'Select Sales Channel' },
                            { value: 'WalkIn', label: 'WalkIn' },
                            { value: 'Register', label: 'Register' },
                            { value: 'Delivery', label: 'Delivery' },
                          ]}
                          onChange={(value) => handleDateChange("order_channel", value)}
                        />
                      </>
                    )
                  }
                  <Button onClick={handleSearch} type="primary">
                    {
                      (selected === "Sales" || selected === "TopItems") ? (
                        <>   Search</>
                      ):<>
                      Submit
                      </>
                    }
                 
                  </Button>
                </Space>
                {
                  (!!filteredOrder?.orders || !!topSalesItems?.topItems || !!products) && (
                    <Button onClick={handleDownload} style={{ backgroundColor: '#00cc99', fontWeight: 'bold', borderColor: '#00cc99', color: '#ffffff' }} type="primary">
                      <DownloadOutlined size={20} />
                      {
                        selected === "Sales" ?
                          <NumberFormat
                            displayType={"text"}
                            value={Math.round(filteredOrder?.totalAmount).toFixed(2)}
                            prefix={currencySymbol}
                            thousandSeparator={true}
                          /> : <>
                            &nbsp;Download
                          </>
                      }
                    </Button>
                  )
                }
              </div>
            </Col>
            {
              (selected === "Sales") && (
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Sales orders={filteredOrder?.orders} />
                </Col>
              )
            }
            {
              (selected === "TopItems") && (
                <Col xs={24} sm={24} md={24} lg={24}>
                  <TopItemSales topItems={topSalesItems?.topItems} />
                </Col>
              )
            }
            {
              (selected === "Items") && (
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Items items={products} currencySymbol={currencySymbol} />
                </Col>
              )
            }
            {
              (selected === "ItemsStock") && (
                <Col xs={24} sm={24} md={24} lg={24}>
                  <ItemsStock items={products} currencySymbol={currencySymbol} />
                </Col>
              )
            }

          </Row>
        </Scrollbars>
      </div>
    </Drawer>
  );
};

const mapStateToProps = (state) => {
  const { filteredOrder, topSalesItems, order_error } = state.order;
  const { products, product_error } = state.product;
  return { filteredOrder, topSalesItems, products, product_error, order_error };
};

const mapDispatchToProps = {
  filterOrderByDates,
  getTopItemsBySales,
  fetchProducts
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderReportPanel);

