import React from "react";
import { Menu, Dropdown, Avatar, Tooltip } from "antd";
import {
  EditOutlined,
  LogoutOutlined
} from '@ant-design/icons';
import Utils from 'utils';
import { AREAS_LIST } from 'server/configuration/config';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import { useHistory } from "react-router-dom";

export const Profile = (props) => {

  const history = useHistory();
  const { user, demo } = props;

  const onEditProfile = () => {
    history.push(`${APP_PREFIX_PATH}/my-profile`)
  }

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex align-items-center">
          <Avatar size={40} src={demo === true ? user.filename : user.secure_url} style={{ marginRight: 5 }} />
          <div className="pl-3 ml-5">
            <h5 className="mb-0">{user.firstname} {user.lastname}</h5>
            <span className="text-muted">{Utils.find(AREAS_LIST, "value", user.area)}</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          <Menu.Item key={1} onClick={e => onEditProfile()}>
            <span>
              <EditOutlined className="mr-3" />
              <span className="font-weight-normal">Edit Profile</span>
            </span>

          </Menu.Item>
          <Menu.Item key={2} onClick={e => props.signOut()}>
            <span>
              <LogoutOutlined className="mr-3" />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Tooltip title="Profile">
      <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
        <Menu className="d-flex align-item-center" mode="horizontal">
          <Menu.Item>
            <Avatar icon={<i className={'fa fa-user-o'} style={{ color: '#0099cc' }}></i>} />
          </Menu.Item>
        </Menu>
      </Dropdown>
    </Tooltip>
  );
}

export default Profile
