import React, { createContext, useReducer, useEffect } from "react";
import axios from 'axios';
import { indentityActionType } from "server/actionType";
import { commonActionType } from "server/actionType";
import identityReducer from "server/reducers/identityReducer";

const initState = {
  token: '',
  isAuthenticated: false,
  user: {},
  setting: {},
  demo: false,
  flow: "",
  suid: "",
  currencySymbol: "",
  loading: false,
  forgot_password_loading: false,
  reset_password_loading: false,
  identity_error: "",
  forgot_password_error_message: "",
  signin_error_message: "",
  reset_password_error_message: "",
  isForgot_password_error: false,
  isSignin_error_message: false,
  isReset_password_error_message: false,
  identity_success: false,
  isReset: false,
  turn_off_onboarding_success: false,
  contact_success: false
};

export const IdentityContext = createContext();
export const IdentityProvider = ({ children }) => {
  const [state, dispatch] = useReducer(identityReducer, initState);

  useEffect(() => {
    const storedUserData = localStorage.getItem('userData');
    if (storedUserData) {
      const userDataObject = JSON.parse(storedUserData);
      dispatch({ type: indentityActionType.SIGN_IN, payload: userDataObject });
    }
  }, [])

  const actions = {
    signUp: async (params) => {
      try {
        dispatch({ type: commonActionType.LOADING });

        const config = {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/formdata'
          },
        };

        const response = await axios.post(`${process.env.REACT_APP_HOST}/user/create`, params, config);
        localStorage.setItem('userData', JSON.stringify(response.data));
        dispatch({ type: indentityActionType.SIGN_UP, payload: response.data });
      } catch (error) {
        dispatch({ type: indentityActionType.ERROR, payload: error?.response?.data?.error });
      }
    },

    signIn: async (params) => {
      try {
        dispatch({ type: commonActionType.LOADING });

        const config = {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        };

        const response = await axios.post(`${process.env.REACT_APP_HOST}/sign-in`, params, config);
        localStorage.setItem('userData', JSON.stringify(response.data));
        dispatch({ type: indentityActionType.SIGN_IN, payload: response.data });

      } catch (error) {
        dispatch({ type: indentityActionType.SIGN_IN_PASSWORD_ERROR, payload: error?.response?.data?.error });
      }
    },

    autoSignIn: (params) => {
      localStorage.setItem('userData', JSON.stringify(params));
      dispatch({ type: indentityActionType.AUTO_SIGN_IN, payload: params });
    },

    forgotPasswordFrontEnd: async (email) => {
      try {
        dispatch({ type: commonActionType.FORGET_PASSWORD_LOADING });

        const config = {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        };

        const params = {
          email: email
        }

        const response = await axios.post(`${process.env.REACT_APP_HOST}/forgot-password-front-end/`, params, config);
        dispatch({ type: indentityActionType.FORGET, payload: response.data });

      } catch (error) {
        dispatch({ type: indentityActionType.FORGET_PASSWORD_ERROR, payload: error?.response?.data?.error });
      }
    },

    resetPassword: async (params, token) => {
      try {
        dispatch({ type: commonActionType.RESET_PASSWORD_LOADING });

        const config = {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Authorization": `Bearer ${token}`
          },
        };

        const response = await axios.post(`${process.env.REACT_APP_HOST}/change-password-front-end`, params, config);
        dispatch({ type: indentityActionType.PASSWORD_RESET, payload: response.data });

      } catch (error) {
        dispatch({ type: indentityActionType.PASSWORD_RESET_ERROR, payload: error?.response?.data?.error });
      }
    },

    signOut: async () => {
      try {
        const config = {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        };

        const response = await axios.post(`${process.env.REACT_APP_HOST}/sign-out`, {}, config);
        if (response.data.success) {
          localStorage.removeItem('userData');
          dispatch({ type: indentityActionType.SIGN_OUT });
        } else {
          console.error('Server-side sign out failed');
        }

      } catch (error) {
        console.error('Error during sign out:', error);
      }
    },

    demonSignIn: (data) => {
      dispatch({ type: indentityActionType.SIGN_IN, payload: data });
    },

    reset: () => {
      dispatch({ type: indentityActionType.RESET });
    },

    resetLoading: () => {
      dispatch({ type: indentityActionType.RESET_LOADING });
    },

    updateDemoStatus: (status) => {
      dispatch({ type: indentityActionType.DEMO_STATUS, payload: status });
    },

    updateShowOnBoardingHelp: async (token, user) => {
      try {
        dispatch({ type: commonActionType.LOADING });

        const config = {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
          withCredentials: true
        };

        const params = {
          id: '00000'
        }

        const response = await axios.post(`${process.env.REACT_APP_HOST}/user/update-show-on-boarding-help`, params, config);
        dispatch({ type: indentityActionType.UPDATE_SHOW_ONBOARDING_HELP, payload: user });

      } catch (error) {
        dispatch({ type: indentityActionType.ERROR, payload: error?.response?.data?.error });
      }
    },

    contactUs: async (body) => {
      try {
        dispatch({ type: commonActionType.LOADING });

        const config = {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          },
        };
        const response = await axios.post(`${process.env.REACT_APP_HOST}/contact/contact-us`, body, config);
        dispatch({ type: indentityActionType.CONTACT_US, payload: true });

      } catch (error) {
        dispatch({ type: indentityActionType.ERROR, payload: error?.response?.data?.error });
      }
    }
  };

  return (
    <IdentityContext.Provider value={{ ...actions, ...state }}>
      {children}
    </IdentityContext.Provider>
  )

}



