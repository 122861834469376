import { saveAs } from 'file-saver';
import { utils, write } from 'xlsx';

const getVAT = (rate, amount) => {
  return (amount * rate) / (100 + 20);
};

const stripeCustomerPortal = async (stripeCustomerId, token) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_HOST}/create-customer-portal-session`, {
      method: "POST",
      headers: {
        'Authorization': `bearer ${token}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        stripeCustomerId: stripeCustomerId
      })
    });

    if (!response.ok) {
      return {
        error: true,
        message: `HTTP error! status: ${response.status}`
      };
    }

    const data = await response.json();
    return {
      url: data.url,
      error: false
    };

  } catch (err) {
    return {
      error: true,
      message: err.message
    };
  }
};

const getStripe = (payments) => {
  const provider =
    (payments || []).find((payment) => payment.provider === "Stripe");
  return provider || null;
};

function currencySymbolMapper(currencySymbol) {
  const currencyMap = {
    "£": "gbp",
    "$": "usd",
    "aed": "aed",
    "afn": "afn",
    "all": "all",
    "amd": "amd",
    "usdc": "usdc",
    "btn": "btn",
    "ghs": "ghs",
    "eek": "eek",
    "lvl": "lvl",
    "svc": "svc",
    "vef": "vef",
    "ltl": "ltl",
    "sll": "sll",
  };

  if (currencySymbol in currencyMap) {
    return currencyMap[currencySymbol];
  } else {
    return "gbp";
  }
}

function ageDate(days) {
  const lastDay = days === '' ? 0 : parseInt(days)
  let currentDate = new Date();
  let daysAgo = new Date(currentDate.setDate(currentDate.getDate() - lastDay));
  return daysAgo
}

const dateConverter = (stringDate) => {
  const parts = stringDate.split('/');
  return `${parts[2]}-${parts[1]}-${parts[0]}`;
};

const timeStampConverter = (timestamp, localFormat) => {
  const formattedDate = new Date(parseInt(timestamp));
  return dateConverter(formattedDate.toLocaleDateString(localFormat));
};

function formatCurrency(currencySymbol, amount) {
  const numericAmount = parseFloat(amount);

  if (isNaN(numericAmount) || !Number.isFinite(numericAmount)) {
    return 'Invalid amount';
  }

  let formattedAmount;
  if (numericAmount >= 1000000) {
    formattedAmount =
      (numericAmount / 1000000).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) + 'M';
  } else if (numericAmount >= 1000) {
    formattedAmount =
      (numericAmount / 1000).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) + 'K';
  } else {
    formattedAmount = numericAmount.toFixed(2);
  }

  return currencySymbol + formattedAmount;
}

function Download(data) {
  const ws = utils.json_to_sheet(data); // Where data is your JSON array
  const wb = utils.book_new();
  utils.book_append_sheet(wb, ws, "Sheet1");

  // Write workbook (use type 'binary')
  const wbout = write(wb, { bookType: 'xlsx', type: 'binary' });

  // Convert binary string to character array
  function s2ab(s) {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
  }

  const currentDate = new Date();
  const formattedDate = currentDate.toISOString().slice(0, 19).replace(/-/g, "").replace("T", "_").replace(/:/g, "");
  const fileName = `_${formattedDate}.xlsx`;

  saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), fileName);

}

export {
  getVAT,
  stripeCustomerPortal,
  getStripe,
  currencySymbolMapper,
  ageDate,
  timeStampConverter,
  formatCurrency,
  Download
}
