import React from 'react'
import { Switch, Route, } from "react-router-dom";
import { FrontendUi } from 'ui/frontend';

export const FrontendLayout = () => {
	
	return (
		<div className="auth-container">
			<Switch>
				<Route path="" component={FrontendUi} />
			</Switch>
		</div>
	)
}

export default FrontendLayout
