import React, { useContext } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import BackendLayout from '../layouts/backendLayout';
import FrontendLayout from "../layouts/frontLoyout";
import PublicLayout from 'layouts/publicLayout';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH,PUBLIC_PREFIX_PATH } from 'configs/AppConfig'
import { IdentityContext } from 'server/action/identityProvider';

export const Ui = () => {
    const { user } = useContext(IdentityContext);

    console.log("useruser", user)
    
    function RouteInterceptor ({ children, user, ...rest }){
        return (
            <Route
                {...rest}
                render={() =>
                    user ? (
                        children
                    ) : (
                        <Redirect
                            to={{
                                pathname: PUBLIC_PREFIX_PATH                               
                            }}
                        />
                    )
                }
            />
        );
    }
    return (
        <Switch>
            <Route exact path="/">
                <Redirect to={PUBLIC_PREFIX_PATH} />
            </Route>
            <Route path={AUTH_PREFIX_PATH}>
                <FrontendLayout />
            </Route>
            <Route path={PUBLIC_PREFIX_PATH}>
                <PublicLayout />
            </Route>
            <RouteInterceptor path={APP_PREFIX_PATH} user={user}>
                <BackendLayout />
            </RouteInterceptor>
        </Switch>
    )
}