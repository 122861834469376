import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

export const BackendRoute
 = () => {
    return (
      <Suspense fallback={<Loading cover="content"/>}>      
          <Switch>
            <Route path={`${APP_PREFIX_PATH}/onboarding`} component={lazy(() => import(`./onboarding`))} />
            <Route path={`${APP_PREFIX_PATH}/dashboard`} component={lazy(() => import(`./dashboard`))} />
            <Route path={`${APP_PREFIX_PATH}/catalogue`} component={lazy(() => import(`./catalogue`))} />
            <Route path={`${APP_PREFIX_PATH}/orders`} component={lazy(() => import(`./orders`))} />
            <Route path={`${APP_PREFIX_PATH}/customers`} component={lazy(() => import(`./customers`))} />    
            <Route path={`${APP_PREFIX_PATH}/users`} component={lazy(() => import(`./users`))} />                  
            <Route path={`${APP_PREFIX_PATH}/setting`} component={lazy(() => import(`./setting`))} />
            <Route path={`${APP_PREFIX_PATH}/my-profile`} component={lazy(() => import(`./my-profile`))} />
            <Route path={`${APP_PREFIX_PATH}/help-center`} component={lazy(() => import(`./help-center`))} />
            <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboard`} />
          </Switch>       
      </Suspense>
    )
  }
  
  export default BackendRoute;

  