import React from 'react';
import { Menu } from 'antd';

export const MenuItem = (props) => {
    const { item, key, ...restProps } = props;  
    return (
        <Menu.Item {...restProps} key={item.path} icon={item.icon}>{item.name}</Menu.Item>
    )
}

export const MenuGroupItem = (props) => {
    const { item, key, ...restProps } = props;
    const {SubMenu} = Menu

    return (
        <SubMenu title={item.name} key={item.path} icon={item.icon} {...restProps}>
            {
                item?.children?.map(element => {
                    return (
                        <Menu.Item key={element.path}>{element.name}</Menu.Item>
                    )
                })
            }
        </SubMenu>
    )
}

