import axios from 'axios';
import { productActionType } from "server/actionType";
import { commonActionType } from "server/actionType";
import handleError from 'utils/error';

export const createProduct = (params, token) => async (dispatch) => {

  dispatch({
    type: commonActionType.LOADING
  });

  const config = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'multipart/form-data',     
    },
    withCredentials: true
  };

  await axios
    .post(`${process.env.REACT_APP_HOST}/product/create`, params, config)
    .then((response) => {
      dispatch({
        type: productActionType.CREATE,
        payload: response.data
      });
    })
    .catch((error) => {
      handleError(productActionType.ERROR, error, dispatch)
    });
};

export const updateOneProduct = (id, params, token) => async (dispatch) => {

  dispatch({
    type: commonActionType.LOADING
  });

  const config = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'multipart/form-data',  
    },
    withCredentials: true
  };

  await axios
    .put(`${process.env.REACT_APP_HOST}/product/${id}`, params, config)
    .then((response) => {
      dispatch({
        type: productActionType.EDIT,
        payload: response.data
      });
    })
    .catch((error) => {
      handleError(productActionType.ERROR, error, dispatch)
    });
}

export const deleteOneProduct = (id, public_id, token, catalogue_template) => async (dispatch) => {

  dispatch({
    type: commonActionType.LOADING
  });


  const config = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',      
    },
    withCredentials: true
  };

  await axios
    .delete(`${process.env.REACT_APP_HOST}/product/${id}/${public_id}/${catalogue_template}`, config)
    .then((response) => {
      dispatch({
        type: productActionType.DELETE,
        payload: id
      });
    })
    .catch((error) => {
      handleError(productActionType.ERROR, error, dispatch)
    });
}

export const fetchProductOne = (product) => (dispatch) => {
  dispatch({ type: productActionType.FETCH_SINGLE, payload: product });
}

export const duplicateOneProduct = (product) => (dispatch) => {
  dispatch({
    type: commonActionType.LOADING
  });

  dispatch({ type: productActionType.DUPLICATE, payload: product });
}

export const resetProduct = () => (dispatch) => {
  dispatch({ type: productActionType.RESET });
}

export const resetProductError = () => (dispatch) => {
  dispatch({ type: productActionType.RESET_ERROR });
}
export const resetProductSuccess = () => (dispatch) => {
  dispatch({ type: productActionType.RESET_SUCCESS });
}
export const fetchProducts = (token) => async (dispatch) => {

  dispatch({
    type: commonActionType.LOADING
  });

  const config = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',     
    },
    withCredentials: true
  };

  await axios
    .get(`${process.env.REACT_APP_HOST}/products`, config)
    .then((response) => {
      dispatch({
        type: productActionType.FETCH,
        payload: response.data
      });
    })
    .catch((error) => {
      handleError(productActionType.ERROR, error, dispatch)
    });
}



