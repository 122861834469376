import axios from "axios";
import { orderActionType } from "server/actionType";
import { commonActionType } from "server/actionType";
import handleError from "utils/error";

export const createOrder = (params, token) => async (dispatch) => {
  dispatch({ type: commonActionType.LOADING });

  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    withCredentials: true
  };

  await axios
    .post(`${process.env.REACT_APP_HOST}/order/create`, params, config)
    .then((response) => {
      dispatch({
        type: orderActionType.CREATE_ORDER,
        payload: response.data,
      });
    })
    .catch((error) => {
      handleError(orderActionType.ERROR, error, dispatch);
    });
};

export const createWorkflow = (params, token, order) => async (dispatch) => {
  dispatch({ type: commonActionType.LOADING });

  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    withCredentials: true
  };

  await axios
    .post(`${process.env.REACT_APP_HOST}/orderworkflow/create`, params, config)
    .then((response) => {
      dispatch({
        type: orderActionType.CREATE_WORKFLOW,
        payload: response.data,
      });
    })
    .catch((error) => {
      handleError(orderActionType.ERROR, error, dispatch);
    });
};

export const fetchOrder = (token) => async (dispatch) => {
  dispatch({ type: commonActionType.LOADING });

  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    withCredentials: true
  };

  await axios
    .get(`${process.env.REACT_APP_HOST}/order/fetch`, config)
    .then((response) => {
      dispatch({
        type: orderActionType.FETCH,
        payload: response.data,
      });
    })
    .catch((error) => {
      handleError(orderActionType.ERROR, error, dispatch);
    });
};

export const filterOrderByDates = (queryParams) => async (dispatch) => {
  dispatch({ type: commonActionType.LOADING });

  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    withCredentials: true
  };

  const queryString = new URLSearchParams(queryParams).toString();

  await axios
    .get(`${process.env.REACT_APP_HOST}/order/fetch-by-order-by-dates?${queryString}`, config)
    .then((response) => {
      dispatch({
        type: orderActionType.FILTER_ORDER_BY_DATES,
        payload: response.data,
      });
    })
    .catch((error) => {
      handleError(orderActionType.ERROR, error, dispatch);
    });
};

export const getTopItemsBySales = (queryParams) => async (dispatch) => {
  dispatch({ type: commonActionType.LOADING });

  const config = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    withCredentials: true
  };

  const queryString = new URLSearchParams(queryParams).toString();

  await axios
    .get(`${process.env.REACT_APP_HOST}/order/fetch-by-top-sales-items?${queryString}`, config)
    .then((response) => {
      dispatch({
        type: orderActionType.FETCH_TOP_SALES_ITEMS,
        payload: response.data,
      });
    })
    .catch((error) => {
      handleError(orderActionType.ERROR, error, dispatch);
    });
};

export const updateOrderStatus =
  (token, body, dispatcher) => async (dispatch) => {
    dispatch({ type: commonActionType.LOADING });

    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      withCredentials: true
    };

    await axios
      .post(`${process.env.REACT_APP_HOST}/order/update-status`, body, config)
      .then((response) => {
        dispatcher({
          type: "UPDATE_ORDER_STATUS",
          payload: response,
        });
      })
      .catch((error) => {
        handleError(orderActionType.ERROR, error, dispatch);
      });
  };

export const updatePaymentStatus =
  (token, body, dispatcher) => async (dispatch) => {
    dispatch({ type: commonActionType.LOADING });

    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      withCredentials: true
    };

    await axios
      .post(
        `${process.env.REACT_APP_HOST}/order/update-payment-status`,
        body,
        config
      )
      .then((response) => {
        dispatcher({
          type: "UPDATE_PAYMENT_STATUS",
          payload: { payment_status: body.payment_status }
        });
      })
      .catch((error) => {
        handleError(orderActionType.ERROR, error, dispatch);
      });
  };

export const fetchOneOrder = (order) => (dispatch) => {
  dispatch({ type: orderActionType.FETCH_ONE, payload: order });
};

export const resetOrder = () => (dispatch) => {
  dispatch({ type: orderActionType.RESET });
};

export const switchOffNotification = () => (dispatch) => {
  dispatch({ type: orderActionType.SWITCH_OFF_NOTIFICATION });
};
