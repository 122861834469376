import { orderActionType, commonActionType } from "server/actionType"

const initOrderState = {
    order: {},
    filteredOrder: {},
    topSalesItems : [],
    orders: [],
    order_loading: false,
    order_success: false,
    order_error: { error: false }
};

const orderReducer = (state = initOrderState, action) => {

    switch (action.type) {

        case orderActionType.FETCH_ONE:
            return {
                ...state,
                order: action.payload,
                order_loading: false,
                order_success: false
            }

        case orderActionType.UPDATE_STATUS:
            return {
                ...state,
                order: action.payload,
                order_loading: false,
                order_success: false
            }

        case orderActionType.FETCH:
            return {
                ...state,
                orders: action.payload,
                order_loading: false,
                order_success: true
            }

        case orderActionType.FILTER_ORDER_BY_DATES:
            return {
                ...state,
                filteredOrder: action.payload,
                order_loading: false,
                order_success: true
            }

        case orderActionType.FETCH_TOP_SALES_ITEMS:
            return {
                ...state,
                topSalesItems: action.payload,
                order_loading: false,
                order_success: true
            }

        case orderActionType.CREATE_ORDER:
            return {
                ...state,
                order_loading: false,
                order_success: true
            }

        case orderActionType.CREATE_WORKFLOW:
            return {
                ...state,
                orders: state.orders.map((order) => {
                    return order._id === action.payload._id ? action.payload : order
                }),
                order: action.payload,
                order_loading: false,
                order_success: true
            }

        case orderActionType.SWITCH_OFF_NOTIFICATION:

            return {
                ...state,
                order_success: false,
                order_error: { error: false }
            }

        case orderActionType.RESET:

            return {
                ...state,
                order_loading: false,
                order_success: false,
                order: {},
                order_error: { error: false }
            }

        case orderActionType.ERROR:
            return {
                ...state,
                order_loading: false,
                order_success: false,
                order_error: action.payload
            }

        case commonActionType.LOADING:
            return {
                ...state,
                order_loading: true
            };

        default:
            return state;
    }

}

export default orderReducer