import { combineReducers } from 'redux';
import Theme from './Theme';
import categoryReducer from 'server/reducers/categoryReducer';
import productReducer from 'server/reducers/productReducer';
import settingsReducer from 'server/reducers/settingsReducer';
import orderReducer from 'server/reducers/orderReducer';
import dashboardReducer from 'server/reducers/dashboardReducer';
import userReducer from 'server/reducers/userReducer';
import appConfigReducer from 'server/reducers/appConfigReducer';
import customerReducer from 'server/reducers/customerReducer';
import subscriptionReducer from 'server/reducers/subscriptionReducer';
import notificationReducer from 'server/reducers/notificationReducer';
import productStockReducer from 'server/reducers/productStockReducer';
import onBoardingTodoReducer from 'server/reducers/onBoardingTodoReducer'

const reducers = combineReducers({
    theme: Theme,
    category: categoryReducer,
    product: productReducer,
    setting: settingsReducer,
    order: orderReducer,
    dashboard: dashboardReducer,
    user: userReducer,
    appConfig: appConfigReducer,
    customer: customerReducer,
    subscription: subscriptionReducer,
    notification: notificationReducer,
    stock: productStockReducer,
    todo: onBoardingTodoReducer    
});

export default reducers;