import React from 'react';
import { UsergroupAddOutlined,  OrderedListOutlined, ProfileFilled, UserOutlined, HomeOutlined } from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const menu = [
    {
        path: `${APP_PREFIX_PATH}/dashboard`,
        name: 'Dashboard',
        icon: <HomeOutlined />,
        permission: null,
        children: undefined
    },
    {
        path: "#",
        name: 'Catalogue',
        icon: <ProfileFilled />,
        permission: null,
        children: [
            {
                path: `${APP_PREFIX_PATH}/catalogue/categories`,
                name: 'Categories',
                permission: null
            },
            {
                path: `${APP_PREFIX_PATH}/catalogue/items`,
                name: 'Items',
                permission: null
            }
        ],
    },
    {
        path: `${APP_PREFIX_PATH}/orders`,
        name: 'Orders',
        icon: <OrderedListOutlined />,
        permission: null,
        children: undefined
    },
    // {
    //     path: `${APP_PREFIX_PATH}/customers`,
    //     name: 'Customers',
    //     icon: <UsergroupAddOutlined />,
    //     permission: null,
    //     children: undefined
    // },
    {
        path: `${APP_PREFIX_PATH}/users`,
        name: 'Users',
        icon: <UserOutlined />,
        permission: null,
        children: undefined
    },
]

export default menu