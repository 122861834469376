import { categoryActionType, commonActionType } from "server/actionType";

const initCategoryState = {
  category: {},
  categories: [],
  category_loading: false,
  category_success: false,
  category_error: {
    error: false,
  },
};

const categoryReducer = (state = initCategoryState, action) => {
  switch (action.type) {
    case categoryActionType.CREATE:
      return {
        ...state,
        categories: [...state.categories, action.payload],
        category_success: true,
        category_loading: false,
        category_error: { error: false },
      };

    case categoryActionType.EDIT:
      return {
        ...state,
        categories: state.categories.map((category) => {
          return category._id === action.payload._id
            ? action.payload
            : category;
        }),
        category_success: true,
        category_loading: false,    
      };

    case categoryActionType.DELETE:   
      return {
        ...state,
        categories: state.categories.filter((x) => x._id !== action.payload),
        category_success: true,
        category_loading: false,
        category_error: { error: false },
      };

    case categoryActionType.FETCH:    
      return {
        ...state,
        categories: action.payload,
        category_loading: false,
      };

    case categoryActionType.FETCH_SINGLE:
      return {
        ...state,
        category: action.payload,
        category_success: false,
        category_loading: false,   
      };

    case categoryActionType.RESET:
      return {
        ...state,
        category: {},      
        category_success: false,
        category_loading: false,
        category_error: { error: false },
      };

    case categoryActionType.ERROR:
      return {
        ...state,
        category_loading: false,
        category_success: false,
        category_error: action.payload,
      };

    case commonActionType.LOADING:
      return {
        ...state,
        category_loading: true,
      };

    default:
      return state;
  }
};

export default categoryReducer;
