import { indentityActionType, commonActionType } from "server/actionType";
export default (state, action) => {
  switch (action.type) {
    case indentityActionType.SIGN_UP:
    case indentityActionType.SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload?.token,
        currencySymbol: action.payload?.currencySymbol,
        setting: action.payload.setting,
        demo: action.payload?.demo,
        flow: action.payload?.flow,
        user: action.payload?.user,
        suid: action.payload?.user?.suid,
        loading: false,
      };

    case indentityActionType.AUTO_SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload?.token,
        currencySymbol: action.payload?.currencySymbol,
        user: action.payload?.user,
        suid: action.payload?.suid,
        demo: action.payload?.demo,
        setting: action.payload?.setting,
        identity_success: false,
        identity_error: "",
        loading: false,
      };

    case indentityActionType.SIGN_OUT:
    case indentityActionType.RESET:
      return {
        ...state,
        isAuthenticated: false,
        token: "",
        currencySymbol: "",
        demo: false,
        suid: "",
        flow: "",
        identity_success: false,
        identity_error: "",
        setting: {},
        user: {},
        loading: false,
        forgot_password_loading: false,
        reset_password_loading: false,
      };

    case indentityActionType.RESET_LOADING:
      return {
        ...state,
        loading: false,
      };

    case indentityActionType.DEMO_STATUS:
      return {
        ...state,
        demo: action.payload,
        setting: { ...state.setting, demo: action.payload },
      };

    case indentityActionType.FORGET:
      return {
        ...state,
        success: action.payload,
        forgot_password_loading: false,
      };

    case indentityActionType.PASSWORD_RESET:
      return {
        ...state,      
        identity_success: false,
        identity_error: "",
        isAuthenticated: false,
        isReset: action.payload,
        reset_password_loading: false,
      };

    case indentityActionType.UPDATE_SHOW_ONBOARDING_HELP:
      return {
        ...state,
        user: { ...action.payload, show_onboarding_help: false },
        turn_off_onboarding_success: true,
      };

    case indentityActionType.CONTACT_US:
      return {
        ...state,
        contact_success: true,
      };

    case indentityActionType.FORGET_PASSWORD_ERROR:
      return {
        ...state,
        forgot_password_error_message: action.payload,
        isForgot_password_error: true,
        forgot_password_loading: false,
      };

    case indentityActionType.SIGN_IN_PASSWORD_ERROR:
      return {
        ...state,
        signin_error_message: action.payload,
        isSignin_error_message: true,
        loading: false,
      };

    case indentityActionType.PASSWORD_RESET_ERROR:
      return {
        ...state,
        reset_password_error_message: action.payload,
        isReset_password_error_message: true,
        reset_password_loading: false,
      };

    case indentityActionType.ERROR:
      return {
        ...state,
        loading: false,
        forgot_password_loading: false,
        reset_password_loading: false,
        identity_success: false,
        isAuthenticated: false,
        error: action.payload,
      };

    case commonActionType.LOADING:
      return {
        ...state,
        loading: true,
      };

    case commonActionType.FORGET_PASSWORD_LOADING:
      return {
        ...state,
        forgot_password_loading: true,
      };

    case commonActionType.RESET_PASSWORD_LOADING:
      return {
        ...state,
        reset_password_loading: true,
      };

    default:
      return state;
  }
};
