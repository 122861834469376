import { userActionType, commonActionType } from "server/actionType"

const initState = {
    user: {},
    users: [],
    user_loading: false,
    user_success: false,
    user_error: { error: false },
    isEdit: false
};

const userReducer = (state = initState, action) => {

    switch (action.type) {
        case userActionType.CREATE:
            return {
                ...state,
                user: action.payload,
                user_loading: false,
                user_success: true
            }

        case userActionType.EDIT:
            return {
                ...state,
                users: state.users.map((user) => { return user._id === action.payload._id ? action.payload : user }),
                user_loading: false,
                user_success: true
            }

        case userActionType.DELETE:
            return {
                ...state,
                users: state.users.filter(x => x._id !== action.payload),
                user_loading: false,
                user_success: true
            }

        case userActionType.FETCH:
            return {
                ...state,
                users: action.payload,
                user_loading: false,
                user_success: true,
                isEdit: false
            }
        case userActionType.FILTER:

            return {
                ...state,
                users: action.payload,
                user_loading: false
            }

        case userActionType.FETCH_SINGLE:

            return {
                ...state,
                user: action.payload,
                user_loading: false,
                isEdit: true
            }

        case userActionType.RESET:

            return {
                ...state,
                user_loading: false,
                user_success: false,
                user_error: { error: false },
                isEdit: false
            }

        case userActionType.ERROR:
            return {
                ...state,
                user_loading: false,
                user_success: false,
                user: {},
                user_error: action.payload
            }

        case commonActionType.USER_LOADING:
            return {
                ...state,
                user_success: false,
                user_loading: true
            };

        default:
            return state;
    }

}

export default userReducer