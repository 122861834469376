import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MenuFoldOutlined, MenuUnfoldOutlined, SettingOutlined, AccountBookOutlined } from '@ant-design/icons';
import { updateOneDemo } from 'server/action/settingsAction';
import { connect } from "react-redux";
import { Layout, Menu, Tooltip } from 'antd'
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import Profile from './profile';
import Notification from './notification';
import { Switch } from 'antd'
import OrderReportPanel from 'ui/backend/shared/report-panel';

const MenuHeader = (props) => {
    const [isPanelOpen, setIsPanelOpen] = useState(false);
    const history = useHistory();
    const { demo, token, user, suid, signOut, updateDemoStatus, collapsed, setCollapsed, updateOneDemo, flow } = props
    const { Header } = Layout;

    const onClick = () => {
        history.push(`${APP_PREFIX_PATH}/setting`)
    }

    const onChange = (checked) => {
        const params = {
            status: checked
        }
        updateOneDemo(suid, params, token)
        updateDemoStatus(checked)
    }

    return (
        <>
            <Header style={{ padding: 0, background: "#fff" }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }} >
                    <div>
                        <Menu mode="horizontal">
                            <Menu.Item key="toggle" onClick={() => { setCollapsed(!collapsed) }} >
                                {collapsed ? <MenuFoldOutlined /> : <MenuUnfoldOutlined />}
                            </Menu.Item>
                        </Menu>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'raw', alignItems: 'center', justifyContent: 'end' }} >
                        {
                            flow && !demo && (
                                <div style={{ display: 'flex', flexDirection: 'raw', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <span>Test mode</span>
                                    <Switch style={{ marginLeft: 4 }} checked={demo} onChange={onChange} />
                                </div>
                            )
                        }
                        {
                            !demo && (
                                <Tooltip title="Reports" placement="top">
                                    <Menu mode="horizontal">
                                        <Menu.Item key="reports" icon={<AccountBookOutlined />} onClick={() => setIsPanelOpen(true)}>
                                        </Menu.Item>
                                    </Menu>

                                </Tooltip>
                            )
                        }
                        {/* <Notification token={token} suid={suid} demo={demo} /> */}
                        <Tooltip title="Settings" placement="top">
                            <Menu mode="horizontal">
                                <Menu.Item key="settings" icon={<SettingOutlined />} onClick={() => { onClick() }}>
                                </Menu.Item>
                            </Menu>
                        </Tooltip>
                        <Profile user={user} demo={demo} signOut={signOut} />
                    </div>
                </div>
            </Header>
            <OrderReportPanel isPanelOpen={isPanelOpen} handlePanelToggle={() => { setIsPanelOpen((option) => !option) }} />
        </>
    )
}

const mapStateToProps = state => {
    const { setting_error, setting_success } = state.setting;
    return { setting_error, setting_success }
}

const mapDispatchToProps = {
    updateOneDemo
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(MenuHeader));
