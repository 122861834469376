import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import { BrowserRouter as Router } from 'react-router-dom';
import { Ui } from '../src/ui';
import { Route, Switch } from 'react-router-dom';
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from './configs/AppConfig';
import { IdentityProvider } from 'server/action/identityProvider'
import ReactGA from 'react-ga';

const App=()=> {
  ReactGA.initialize('UA-320380705');

  const themes = {
    dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
    light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
  };

  return (
    <div className="App">
      <Provider store={store}>       
          <ThemeSwitcherProvider themeMap={themes} defaultTheme={THEME_CONFIG.currentTheme} insertionPoint="styles-insertion-point">
            <IdentityProvider>            
                <Router>
                  <Switch>
                    <Route path="/" component={Ui}/>
                  </Switch>
                </Router>            
            </IdentityProvider>
          </ThemeSwitcherProvider> 
      </Provider>
    </div>
  );
}

export default App;
