import { dashboardActionType, commonActionType } from "server/actionType"

const initDashboard = {
    orderStatuses: [],
    weeklySales: {},
    paymentStatus: [],
    recentOrders: [],
    recentOrders: [],
    dashBoardAggregates: {},
    dashboard_error: { error : false},
    dashboard_loading: false
};

const dashboardReducer = (state = initDashboard, action) => {

    switch (action.type) {

        case dashboardActionType.FETCH_DASHBOARD_AGGREGATES:
            return {
                ...state,
                dashBoardAggregates: action.payload,
                dashboard_loading: false
            }

        case dashboardActionType.FETCH_ORDER_STATUS:
            return {
                ...state,
                orderStatuses: action.payload,
                dashboard_loading: false
            }

        case dashboardActionType.FETCH_WEEKLY_SALES:
            return {
                ...state,
                weeklySales: action.payload,
                dashboard_loading: false
            }

        case dashboardActionType.FETCH_PAYMENT_STATUS:
            return {
                ...state,
                paymentStatus: action.payload,
                dashboard_loading: false
            }

        case dashboardActionType.FETCH_TOP_ORDERS:
            return {
                ...state,
                recentOrders: action.payload,
                dashboard_loading: false
            }

        case dashboardActionType.ERROR:
            return {
                ...state,
                dashboard_loading: false,              
                dashboard_error: action.payload
            }

        case commonActionType.LOADING:
            return {
                ...state,
                dashboard_loading: true
            };

        default:
            return state;
    }

}

export default dashboardReducer