import { settingsActionType, commonActionType } from "server/actionType";

const initSettings = {
  setting: {},
  verify_online: {},
  setting_loading: false,
  setting_success: false,
  populate_catalogue_success: false,
  populate_catalogue_loading: false,
  verify_online_success: false,
  enable_online_shopping_success: false,
  isEdit: false,
  setting_error: { error: false },
  payment_provider_loading: false,
  payment_provider_success: false,
  delivery_schedule_loading: false,
  delivery_schedule_success: false,
  shipping_method_loading: false,
  shipping_method_success: false,
  verify_address: [],
  isEligible : false,
  checklist : []
};

const settingsReducer = (state = initSettings, action) => {
  switch (action.type) {
    case settingsActionType.CREATE:
      return {
        ...state,
        setting: action.payload,
        setting_loading: false,
        setting_success: true,
      };

    case settingsActionType.EDIT:
      return {
        ...state,
        setting_loading: false,
        setting_success: true,
      };

    case settingsActionType.DELETE:
      return {
        ...state,
        setting: {},
        setting_loading: false,
        setting_success: true,
      };

    case settingsActionType.FETCH_ONE:
      return {
        ...state,
        setting: action.payload,
        setting_loading: false,
        isEdit: true,
      };

    case settingsActionType.UPDATE_PRICE:
      return {
        ...state,
        setting: { ...state.setting, priceId: action.payload },
        setting_loading: false,
        setting_success: true,
      };

    case settingsActionType.CREATE_DELIVERY_SLOTS:
    case settingsActionType.UPDATE_DELIVERY_SLOTS:
    case settingsActionType.REMOVE_DELIVERY_SLOTS:
      return {
        ...state,
        setting: { ...state.setting, delivery_slots: action.payload },
        delivery_schedule_success: true,
        delivery_schedule_loading: false,
      };

    case settingsActionType.CREATE_DELIVERY_PAYMENT_PROVIDER:
      return {
        ...state,
        setting: {
          ...state.setting,
          delivery_payment_provider: action.payload,
        },
        payment_provider_loading: false,
        payment_provider_success: true,
      };

    case settingsActionType.UPDATE_DELIVERY_PAYMENT_PROVIDER:  
      return {
        ...state,
        setting: {
          ...state.setting,
          delivery_payment_provider:
            state?.setting?.delivery_payment_provider.map((x) => {
              return x._id === action.payload.delivery_payment_provider_id
                ? action.payload
                : x;
            }),
        },
        payment_provider_loading: false,
        payment_provider_success: true,
      };

    case settingsActionType.REMOVE_DELIVERY_PAYMENT_PROVIDER:
      return {
        ...state,
        setting: {
          ...state.setting,
          delivery_payment_provider:
            state.setting?.delivery_payment_provider?.filter(
              (x) => x._id !== action.payload
            ),
        },
        payment_provider_loading: false,
        payment_provider_success: true,
      };

    case settingsActionType.DEMO:
      return {
        ...state,
        setting: { ...state.setting, demo: action.payload },
      };

    case settingsActionType.POPULATE_CATALOGUE:
      return {
        ...state,
        populate_catalogue_loading: false,
        populate_catalogue_success: true,
      };

    case settingsActionType.VERIFY_ONLINE:
      return {
        ...state,
        setting_loading: false,
        verify_online_success: true,
        verify_online: action.payload,
      };

    case settingsActionType.ELIGIBLE_FOR_ENABLING:
  
      return {
        ...state,       
        setting_loading: false,
        enable_online_shopping_success: true,
        isEligible: action.payload.isEligible,
        checklist : action.payload.checklist
      };

       case settingsActionType.CHANGE_ONLINE_SHOPPING:
      return {
        ...state,
        setting: { ...state.setting, enable_shopping: true },
        setting_loading: false,
        enable_online_shopping_success: true,
      };

    case settingsActionType.VERIFY_ADDRESS:
      return {
        ...state,
        setting_loading: false,
        verify_address: action.payload,
      };

    case settingsActionType.CREATE_SHIPPING_METHOD:
      return {
        ...state,
        setting: { ...state.setting, shipping_methods: action.payload },
        shipping_method_loading: false,
        shipping_method_success: true
      };
    case settingsActionType.UPDATE_SHIPPING_METHOD:
      return {
        ...state,
        setting: {
          ...state.setting,
          shipping_methods:
            state?.setting?.shipping_methods.map((x) => {
              return x._id === action.payload._id
                ? action.payload
                : x;
            }),
        },
       shipping_method_loading: false,
        shipping_method_success: true,
      };
    case settingsActionType.REMOVE_SHIPPING_METHOD:
     return {
        ...state,
        setting: {
          ...state.setting,
          shipping_methods:
            state.setting?.shipping_methods?.filter(
              (x) => x._id !== action.payload
            ),
        },
        shipping_method_loading: false,
        shipping_method_success: true,
      };

    case settingsActionType.RESET:
      return {
        ...state,
        isEdit: false,
        setting_loading: false,
        setting_success: false,
        setting_error: { error: false },
        delivery_schedule_success: false,
        delivery_schedule_loading: false,
        payment_provider_loading: false,
        payment_provider_success: false,
        populate_catalogue_success: false,
        verify_online_success: false,
        enable_online_shopping_success: false,
        shipping_method_loading: false,
        shipping_method_success: false
      };

    case settingsActionType.ERROR:
      return {
        ...state,
        setting_loading: false,
        setting_success: false,
        delivery_schedule_success: false,
        delivery_schedule_loading: false,
        payment_provider_loading: false,
        payment_provider_success: false,
        setting_error: action.payload,
      };

    case commonActionType.LOADING:
      return {
        ...state,
        setting_loading: true,
      };

    case commonActionType.LOADING_CATALOGUE:
      return {
        ...state,
        populate_catalogue_loading: true,
      };

    default:
      return state;
  }
};

export default settingsReducer;
