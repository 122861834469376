import { subscriptionActionType, commonActionType } from "server/actionType"

const initSubscription = {
    subscription: {},
    subscription_loading: false,
    subscription_success: false,
    subscription_upgrade_loading: false,
    subscription_upgrade_success: false,
    isCancel: false,
    isUpdate: false,
    invoices: [],
    invoice: {},
    subscription_error: { error: false }
};

const subscriptionReducer = (state = initSubscription, action) => {

    switch (action.type) {
        case subscriptionActionType.CREATE:
            return {
                ...state,
                subscription: action?.payload,
                subscription_loading: false,
                subscription_success: true
            }
        case subscriptionActionType.FETCH_UP_COMING_INVOICE:
            return {
                ...state,
                invoice: action?.payload,
                subscription_loading: false,
                subscription_success: true
            }
        case subscriptionActionType.CUSTOMER_INVOICES:
            return {
                ...state,
                invoices: action?.payload,
                subscription_loading: false,
                subscription_success: true
            }
        case subscriptionActionType.CREATE_PAYMENT_METHOD:
            return {
                ...state,
                subscription_loading: false,
                subscription_success: true
            }
        case subscriptionActionType.CANCEL:
            return {
                ...state,
                subscription_loading: false,
                subscription_success: true,
                isCancel: true
            }

        case subscriptionActionType.UPDATE:
            return {
                ...state,
                subscription_upgrade_loading: false,
                subscription_upgrade_success: true,
                isUpdate: action.payload
            }
        case subscriptionActionType.RESET_SUBSCRIPTION:
            return {
                ...state,
                subscription_loading: false,
                subscription_success: false,
                subscription_upgrade_loading: false,
                subscription_upgrade_success: false,
                subscription: {},
                isCancel: false,
                subscription_error: { error: false }
            }
        case subscriptionActionType.ERROR:
            return {
                ...state,
                subscription_loading: false,
                subscription_success: false,
                subscription_error: action.payload
            }
        case commonActionType.LOADING:
            return {
                ...state,
                subscription_loading: true
            };

        case commonActionType.subscription_upgrade_loading:
            return {
                ...state,
                subscription_upgrade_loading: true
            };

        default:
            return state;
    }

}

export default subscriptionReducer