import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { Layout } from 'antd'
import { BackendRoute } from 'ui/backend'
import Sitelayout from 'components/styles';

const Contents = () => {
    const { Content } = Layout;
    return (
        <Scrollbars autoHide>
            <Content
                className={Sitelayout.sitelayoutbackground}
                style={{
                    margin: '1px 1px',
                    paddingTop: 14,
                    paddingLeft: 12,
                    paddingRight: 12,
                    minHeight: 280               
                }}
            >
                <BackendRoute />
            </Content>
        </Scrollbars>
    )
}

export default Contents