import { onBoardingTodoActionType, commonActionType } from "server/actionType";

const initializeOnBoardingTodoState = {
  todos: {},
  todo_loading: false,
  todo_success: false,
  todo_error: { error: false },
};

const onBoardingTodoReducer = (state = initializeOnBoardingTodoState, action) => {
  switch (action.type) {
    case onBoardingTodoActionType.FETCH_ONBOARDING_TODO:
      return {
        ...state,
        todos: action.payload,
      };
   
    case onBoardingTodoActionType.ERROR:
      return {
        ...state,
        todo_success: false,
        todo_loading: false,
        todo_error: action.payload,
      };

    case commonActionType.LOADING:
      return {
        ...state,
        todo_loading: true,
      };

    default:
      return state;
  }
};

export default onBoardingTodoReducer;
