import React from 'react';
import { Avatar, Drawer, List, Button } from 'antd';
import { DATE_FORMAT_DD_MM_YYYY } from 'configs/DateConstant';
import moment from 'moment';
import {
	MailOutlined,
	WarningOutlined,
	CheckCircleOutlined
} from '@ant-design/icons';

const NotificationView = (props) => {

	const { notices, visible, close, onShowNotificationDetails } = props;

	const getIcon = icon => {
		switch (icon) {
			case 'mail':
				return <MailOutlined />;
			case 'alert':
				return <WarningOutlined />;
			case 'check':
				return <CheckCircleOutlined />
			default:
				return <MailOutlined />;
		}
	}

	return (
		<Drawer
			width={300}
			placement="right"
			onClose={close}
			closable={false}
			visible={visible}
		>
			<div className="nav-notification-header d-flex justify-content-between align-items-center">
				<h4 className="mb-0">Notifications</h4>
				<Button type="link" onClick={() => close()} size="small">Close </Button>
			</div>

			<List
				size="small"
				itemLayout="horizontal"
				dataSource={notices}
				renderItem={item => (
					<List.Item className="list-clickable" onClick={() => onShowNotificationDetails(item)}>
						<div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
							<div className="pr-3">
								{<Avatar className={`ant-avatar-${item?.status === true ? "success" : item.type}`} style={{ marginRight: 4 }} icon={getIcon(item.icon)} /> }
							</div>
							<div style={{ width: '100%' }}>
								<span className="font-weight text-dark">{item.title} </span><br />
								<small style={{ marginRight: 5 }}>{moment(item.createdAt).format(DATE_FORMAT_DD_MM_YYYY)}</small>
								<small>{item.time}</small>
							</div>

						</div>
					</List.Item>
				)}
			/>
		</Drawer>
	)
}

export default NotificationView


