import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { AUTH_PREFIX_PATH } from 'configs/AppConfig'

export const FrontendUi = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>           
        <Route path={`${AUTH_PREFIX_PATH}/shared/error`} component={lazy(() => import(`./shared/error`))} />
        <Route path={`${AUTH_PREFIX_PATH}/sign-in`} component={lazy(() => import(`./sign-in`))} />
        <Route path={`${AUTH_PREFIX_PATH}/forgot-password`} component={lazy(() => import(`./forgot-password`))} />
        <Route path={`${AUTH_PREFIX_PATH}/reset-password/:id`} component={lazy(() => import(`./reset-password`))} />
        <Route path={`${AUTH_PREFIX_PATH}/forgot-password-completed`} component={lazy(() => import(`./forgot-password-completed`))} />
        <Route path={`${AUTH_PREFIX_PATH}/reset-password-completed`} component={lazy(() => import(`./reset-password-completed`))} />
        <Redirect from={`${AUTH_PREFIX_PATH}`} to={`${AUTH_PREFIX_PATH}/sign-in`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(FrontendUi);
