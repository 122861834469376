import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE, DIR_LTR } from './ThemeConstant';

export const APP_NAME = 'iShopy';
export const APP_PREFIX_PATH = '/secure';
export const AUTH_PREFIX_PATH = '/account';
export const PUBLIC_PREFIX_PATH = '/app';

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'en',
	navType: NAV_TYPE_SIDE,
	topNavColor: '#3e82f7',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'light',
	direction: DIR_LTR
};
