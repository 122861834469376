import React from 'react';
import { useHistory } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { MenuItem, MenuGroupItem } from './menu-item';
import menu from './menu';
import { APP_NAME } from 'configs/AppConfig';

const SideBar = (props) => {

    const history = useHistory();
    const { collapsed } = props;
    const { Sider } = Layout;

    const onClickMenu = (item,  key, keyPath) => {       
        history.push(key)
    }

    const SideBarLogo = () => (
        <div style={{ margin: 16, paddingTop: 10, display: 'flex', flexDirection: 'raw', justifyContent: 'center' }} >
            {
                <img style={{backgroundColor : 'transparent'}} width={collapsed ===true ?18: 18} height={collapsed ===true ? 46 : 46} src='/img/logo-small.png' alt="" />
            }
        </div>
    )

    return (
        <Sider width={collapsed ? "80" : "250"} collapsed={collapsed} style={{
            overflow: 'auto',
            height: '100vh',
            left: 0,
        }}>
            <SideBarLogo />
            <Menu
                mode="inline"
                theme="dark"
                defaultSelectedKeys={['0']}
                selectedKeys={`${APP_NAME}`}
                onClick={({ item, key, keyPath }) => onClickMenu(item, key, keyPath)}
            >
                {
                    menu.map((item, key) => {
                        if (item?.children) {
                            return <MenuGroupItem item={item} key={key} />
                        } else {
                            return <MenuItem item={item} key={item.path} />
                        }
                    })
                }
            </Menu>
        </Sider>
    )
}

export default SideBar

