import { productActionType, commonActionType } from "server/actionType"

const initState = {
    product: {},
    products: [],
    product_loading: false,
    product_success: false,
    product_edit: false,
    isDuplicate: false,
    product_error: { error: false },
    product_stock_loading: false,
    product_stock_success: false
};

const productReducer = (state = initState, action) => {

    switch (action.type) {
        case productActionType.CREATE:
            return {
                ...state,              
                product_loading: false,
                product_success: true,
                product_edit: false
            }

        case productActionType.EDIT:
            return {
                ...state,
                products: state.products.map((product) => { return product._id === action.payload._id ? action.payload : product }),
                product_success: true,
                product_loading: false,
                product: {}
            }

        case productActionType.DELETE:
            return {
                ...state,
                products: state.products.filter(x => x._id !== action.payload),
                product_success: true,
                product_loading: false
            }

        case productActionType.FETCH:
            return {
                ...state,
                products: action.payload,
                product_success: false,
                product_loading: false
            }

        case productActionType.FETCH_SINGLE:
            return {
                ...state,
                product: action.payload,
                product_edit: true,
                product_loading: false,
                product_success: false

            }

        case productActionType.DUPLICATE:
            return {
                ...state,
                product: action.payload,
                isDuplicate: true,
                product_loading: false,
                product_success: false
            }      
            

        case productActionType.CREATE_ITEM_STOCK:
            return {
                ...state,
                products: state.products.map((product) => { return product._id === action.payload._id ? action.payload : product }),
                product_stock_loading: false,
                product_stock_success: true
            }

        case productActionType.REMOVE_STOCK:
           
            return {
                ...state,
                products: state.products.map((product) => { return product._id === action.payload._id ? action.payload : product }),
                product_stock_loading: false,
                product_stock_success: true
            }


        case productActionType.RESET:

            return {
                ...state,
                product_edit: false,
                product_loading: false,
                product_success: false,
                product_error: { error: false },
                isDuplicate: false
            }

        case productActionType.RESET_ERROR:

            return {
                ...state,
                product_error: { error: false },
            }

        case productActionType.RESET_SUCCESS:

            return {
                ...state,
                product_success: false
            }

        case productActionType.ERROR:
            return {
                ...state,
                product_loading: false,
                product_success: false,
                product_edit: false,               
                product_error:  action.payload
            }

        case commonActionType.LOADING:
            return {
                ...state,
                product_loading: true
            };

        default:
            return state;
    }

}

export default productReducer