 const Sitelayout ={

    sitelayout: {
        background: "fff"
    },
    sitelayoutbackground :{
        background: "fff"
    },
    trigger : {
        padding: "0 24px",
        fontSize: "18px",
        lineHeight: "64px",
        cursor: "pointer",
        transition: "color 0.3s"
    },
    triggerhover :{
        color: "1890ff"
    },
    logo :{
        height: "32px",
        margin: "16px",
        background: "rgba(255, 255, 255, 0.3)"
    }
 }

 export default Sitelayout;